import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const DashboardRoutes = [
  {
    children: [
      {
        component: () => import('@/views/Admin/Dashboard/Dashboard'),
        path: 'dashboard',
      },
      {
        children: [
          {
            component: () => import('@/views/Admin/NewClient/NewClient'),
            meta: {
              title: 'New Client',
            },
            path: 'new',
          },
          {
            component: () => import('@/views/Admin/AlbumDetail/AlbumDetail'),
            meta: {
              title: 'Album Details',
            },
            path: ':clientId/album/:albumId',
          },
          {
            component: () => import('@/views/Admin/ClientDetails/ClientDetails'),
            path: ':id',
          },
        ],
        component: () => import('@/views/Admin/Client/Client'),
        meta: {
          title: 'Clients',
        },
        path: 'clients',
      },
      {
        children: [
          {
            component: () => import('@/views/Admin/TeamDetails/TeamDetails'),
            meta: {
              title: 'Team Member Details',
            },
            path: ':id',
          },
        ],
        component: () => import('@/views/Admin/Team/Team'),
        meta: {
          title: 'Team',
        },
        path: 'team',
      },
      {
        children: [
          {
            component: () => import('@/views/Admin/NewBooking/NewBooking'),
            meta: {
              title: 'New Booking',
            },
            path: 'new',
          },
          {
            component: () => import('@/views/Admin/BookingDetail/BookingDetail'),
            meta: {
              title: 'Booking Detail',
            },
            path: ':id',
          },
          {
            component: () => import('@/views/Admin/NewBooking/NewBooking'),
            meta: {
              title: 'Edit Booking',
            },
            path: ':id/edit',
          },
          {
            component: () => import('@/views/Admin/BookingCalendar/BookingCalendar'),
            path: '',
          },
        ],
        component: () => import('@/views/Admin/Bookings/Bookings'),
        meta: {
          title: 'Bookings',
        },
        path: 'bookings',
      },
      {
        component: () => import('@/views/Admin/Settings/BookingSlots/BookingSlots'),
        meta: {
          title: 'Slots - Settings',
        },
        path: 'settings/slots',
      },
      {
        component: () => import('@/views/Admin/Messages/Messages'),
        meta: {
          title: 'Messages',
        },
        path: 'messages/:messageId?',
      },
      {
        component: () => import('@/views/Admin/Settings/Offerings/Offerings'),
        meta: {
          title: 'Offerings - Settings',
        },
        path: 'settings/offerings',
      },
      {
        component: () => import('@/views/Admin/Settings/Offerings/Offerings'),
        meta: {
          title: 'Offering Details',
        },
        path: 'settings/offerings/:offeringId',
      },
      {
        component: () => import('@/views/Admin/Settings/Users/Users'),
        meta: {
          title: 'Users',
        },
        path: 'settings/users',
      },
      {
        component: () => import('@/views/Admin/Settings/Users/Users'),
        meta: {
          title: 'Edit User',
        },
        path: 'settings/users/:id',
      },
      // TODO: Remove
      {
        component: () => import('@/views/Admin/Dashboard/Dashboard'),
        meta: {
          title: 'Dashboard',
        },
        path: '*',
      },
    ],
    component: () => import('@/components/Admin/DashboardLayout/DashboardLayout'),
    name: 'admin-dashboard',
    path: '/admin/',
  },
];

const StaticRoutes = [
  {
    component: () => import(/* webpackChunkName: "home" */ '@/views/Static/Home/Home'),
    name: 'home',
    path: '/',
  },
  {
    component: () => import(/* webpackChunkName: "home" */ '@/views/Static/Contact/Contact'),
    meta: {
      title: 'Contact Us',
    },
    name: 'contact',
    path: '/contact',
  },
  {
    component: () => import('@/views/Static/Booking/Bookings'),
    meta: {
      title: 'Create Booking',
    },
    name: 'booking-public',
    path: '/booking',
  },
  {
    component: () => import('@/views/Static/About/About'),
    meta: {
      title: 'About',
    },
    name: 'about',
    path: '/about',
  },
  {
    component: () => import('@/views/Static/Story/Story'),
    meta: {
      title: 'Story',
    },
    name: 'Story',
    path: '/story',
  },
  {
    component: () => import('@/views/Static/Services/Services'),
    meta: {
      title: 'Services',
    },
    name: 'services',
    path: '/services',
  },
  {
    component: () => import('@/views/Static/Portfolio/Portfolio'),
    meta: {
      title: 'Portfolio',
    },
    name: 'portfolio',
    path: '/portfolio',
  },
  {
    component: () => import('@/views/Static/Terms/Terms'),
    meta: {
      title: 'Terms & Condition',
    },
    name: 'terms',
    path: '/terms',
  },
];

const ClientRoutes = [
  {
    component: () => import('@/views/Client/SetPassword/SetPassword'),
    name: 'client-invite',
    path: '/client/invite',
  },
  {
    children: [
      {
        component: () => import('@/views/Client/ClientAlbums/ClientAlbums'),
        meta: {
          title: 'My Albums',
        },
        name: 'client-albums',
        path: 'albums',
      },
      {
        component: () => import('@/views/Client/ClientAlbumDetail/ClientAlbumDetail'),
        meta: {
          title: 'Album Detail',
        },
        name: 'client-album-detail',
        path: 'albums/:id',
      },
    ],
    component: () => import('@/views/Client/ClientLayout/ClientLayout'),
    name: 'client-layout',
    path: '/client',
  },
];

const routes = [
  ...ClientRoutes,
  ...DashboardRoutes,
  ...StaticRoutes,
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
