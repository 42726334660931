import Vue from 'vue';
import VModal from 'vue-js-modal';

import App from './App';
import router from './router';
import store from './store';

// Global Styles
import './styles/normalize.css';
import './styles/variables.css';
import './styles/globals.css';
import './styles/inputs.css';

import 'vue-datetime/dist/vue-datetime.css';

Vue.use(VModal);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app');
