import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const MUTATION_KEYS = {
  setUser: 'setUser',
  updateAllBookings: 'updateAllBookings',
  updateGroupedBookings: 'updateGroupedBookings',
};

export default new Vuex.Store({
  actions: {
  },
  modules: {
  },
  mutations: {
    [MUTATION_KEYS.setUser](state, payload) {
      state.user = payload;
    },

    [MUTATION_KEYS.updateAllBookings](state, payload) {
      state.allBookings = payload;
    },

    [MUTATION_KEYS.updateGroupedBookings](state, payload) {
      state.groupedBookings = payload;
    },
  },
  state: {
    allBookings: [],
    groupedBookings: [],
    user: null,
  },
});
