import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/performance';

const config = {
  apiKey: 'AIzaSyCZrF1_UqKJ20MiqYf8QGU4ZYcdwOn_y94',
  appId: '1:578047724407:web:a50185f6f9d57fd085ef72',
  authDomain: 'staging-teefal.firebaseapp.com',
  databaseURL: 'https://staging-teefal.firebaseio.com',
  measurementId: 'G-ML2JZ10RR9',
  messagingSenderId: '578047724407',
  projectId: 'staging-teefal',
  storageBucket: 'staging-teefal.appspot.com',
};

firebase.initializeApp(config);

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export default firebase;
