import Vue from 'vue';

import firebase from '@/services/firebase';
import { MUTATION_KEYS } from './store';

const App = Vue.extend({
  mounted() {
    if (
      window.location.pathname.includes('admin')
      || window.location.pathname.includes('client')
    ) {
      firebase
        .auth()
        .onAuthStateChanged((user) => {
          this.$store.commit(MUTATION_KEYS.setUser, user);
        });
    }
  },

  render() {
    return (
      <div id="app">
        <transition>
          <router-view />
        </transition>
      </div>
    );
  },

  watch: {
    $route(to) {
      const title = to.meta.title || '';

      document.title = `${title ? `${title} | ` : ''}Teefal Studios`;
    },
  },
});

export default App;
